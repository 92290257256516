.bg-blue-100 { background-color: $blue-100 !important; color: $gray-900 !important; }
.bg-blue-200 { background-color: $blue-100 !important; color: $gray-900 !important; }
.bg-blue-300 { background-color: $blue-300 !important; color: $gray-900 !important; }
.bg-blue-400 { background-color: $blue-400 !important; color: white !important; }
.bg-blue-500 { background-color: $blue-500 !important; color: white !important; }
.bg-blue-600 { background-color: $blue-600 !important; color: white !important; }
.bg-blue-700 { background-color: $blue-700 !important; color: white !important; }
.bg-blue-800 { background-color: $blue-800 !important; color: white !important; }
.bg-blue-900 { background-color: $blue-900 !important; color: white !important; }

.bg-indigo-100 { background-color: $indigo-100 !important; color: $gray-900 !important; }
.bg-indigo-200 { background-color: $indigo-100 !important; color: $gray-900 !important; }
.bg-indigo-300 { background-color: $indigo-300 !important; color: white !important; }
.bg-indigo-400 { background-color: $indigo-400 !important; color: white !important; }
.bg-indigo-500 { background-color: $indigo-500 !important; color: white !important; }
.bg-indigo-600 { background-color: $indigo-600 !important; color: white !important; }
.bg-indigo-700 { background-color: $indigo-700 !important; color: white !important; }
.bg-indigo-800 { background-color: $indigo-800 !important; color: white !important; }
.bg-indigo-900 { background-color: $indigo-900 !important; color: white !important; }

.bg-purple-100 { background-color: $purple-100 !important; color: $gray-900 !important; }
.bg-purple-200 { background-color: $purple-100 !important; color: $gray-900 !important; }
.bg-purple-300 { background-color: $purple-300 !important; color: white !important; }
.bg-purple-400 { background-color: $purple-400 !important; color: white !important; }
.bg-purple-500 { background-color: $purple-500 !important; color: white !important; }
.bg-purple-600 { background-color: $purple-600 !important; color: white !important; }
.bg-purple-700 { background-color: $purple-700 !important; color: white !important; }
.bg-purple-800 { background-color: $purple-800 !important; color: white !important; }
.bg-purple-900 { background-color: $purple-900 !important; color: white !important; }

.bg-pink-100 { background-color: $pink-100 !important; color: $gray-900 !important; }
.bg-pink-200 { background-color: $pink-100 !important; color: $gray-900 !important; }
.bg-pink-300 { background-color: $pink-300 !important; color: $gray-900 !important; }
.bg-pink-400 { background-color: $pink-400 !important; color: white !important; }
.bg-pink-500 { background-color: $pink-500 !important; color: white !important; }
.bg-pink-600 { background-color: $pink-600 !important; color: white !important; }
.bg-pink-700 { background-color: $pink-700 !important; color: white !important; }
.bg-pink-800 { background-color: $pink-800 !important; color: white !important; }
.bg-pink-900 { background-color: $pink-900 !important; color: white !important; }

.bg-red-100 { background-color: $red-100 !important; color: $gray-900 !important; }
.bg-red-200 { background-color: $red-200 !important; color: $gray-900 !important; }
.bg-red-300 { background-color: $red-300 !important; color: $gray-900 !important; }
.bg-red-400 { background-color: $red-400 !important; color: white !important; }
.bg-red-500 { background-color: $red-500 !important; color: white !important; }
.bg-red-600 { background-color: $red-600 !important; color: white !important; }
.bg-red-700 { background-color: $red-700 !important; color: white !important; }
.bg-red-800 { background-color: $red-800 !important; color: white !important; }
.bg-red-900 { background-color: $red-900 !important; color: white !important; }

.bg-orange-100 { background-color: $orange-100 !important; color: $gray-900 !important; }
.bg-orange-200 { background-color: $orange-200 !important; color: $gray-900 !important; }
.bg-orange-300 { background-color: $orange-300 !important; color: $gray-900 !important; }
.bg-orange-400 { background-color: $orange-400 !important; color: $gray-900 !important; }
.bg-orange-500 { background-color: $orange-500 !important; color: $gray-900 !important; }
.bg-orange-600 { background-color: $orange-600 !important; color: white !important; }
.bg-orange-700 { background-color: $orange-700 !important; color: white !important; }
.bg-orange-800 { background-color: $orange-800 !important; color: white !important; }
.bg-orange-900 { background-color: $orange-900 !important; color: white !important; }

.bg-yellow-100 { background-color: $yellow-100 !important; color: $gray-900 !important; }
.bg-yellow-200 { background-color: $yellow-200 !important; color: $gray-900 !important; }
.bg-yellow-300 { background-color: $yellow-300 !important; color: $gray-900 !important; }
.bg-yellow-400 { background-color: $yellow-400 !important; color: $gray-900 !important; }
.bg-yellow-500 { background-color: $yellow-500 !important; color: $gray-900 !important; }
.bg-yellow-600 { background-color: $yellow-600 !important; color: $gray-900 !important; }
.bg-yellow-700 { background-color: $yellow-700 !important; color: white !important; }
.bg-yellow-800 { background-color: $yellow-800 !important; color: white !important; }
.bg-yellow-900 { background-color: $yellow-900 !important; color: white !important; }

.bg-green-100 { background-color: $green-100 !important; color: $gray-900 !important; }
.bg-green-200 { background-color: $green-200 !important; color: $gray-900 !important; }
.bg-green-300 { background-color: $green-300 !important; color: $gray-900 !important; }
.bg-green-400 { background-color: $green-400 !important; color: $gray-900 !important; }
.bg-green-500 { background-color: $green-500 !important; color: white !important; }
.bg-green-600 { background-color: $green-600 !important; color: white !important; }
.bg-green-700 { background-color: $green-700 !important; color: white !important; }
.bg-green-800 { background-color: $green-800 !important; color: white !important; }
.bg-green-900 { background-color: $green-900 !important; color: white !important; }

.bg-teal-100 { background-color: $teal-100 !important; color: $gray-900 !important; }
.bg-teal-200 { background-color: $teal-200 !important; color: $gray-900 !important; }
.bg-teal-300 { background-color: $teal-300 !important; color: $gray-900 !important; }
.bg-teal-400 { background-color: $teal-400 !important; color: $gray-900 !important; }
.bg-teal-500 { background-color: $teal-500 !important; color: $gray-900 !important; }
.bg-teal-600 { background-color: $teal-600 !important; color: $gray-900 !important; }
.bg-teal-700 { background-color: $teal-700 !important; color: white !important; }
.bg-teal-800 { background-color: $teal-800 !important; color: white !important; }
.bg-teal-900 { background-color: $teal-900 !important; color: white !important; }

.bg-cyan-100 { background-color: $cyan-100 !important; color: $gray-900 !important; }
.bg-cyan-200 { background-color: $cyan-200 !important; color: $gray-900 !important; }
.bg-cyan-300 { background-color: $cyan-300 !important; color: $gray-900 !important; }
.bg-cyan-400 { background-color: $cyan-400 !important; color: white !important; }
.bg-cyan-500 { background-color: $cyan-500 !important; color: white !important; }
.bg-cyan-600 { background-color: $cyan-600 !important; color: white !important; }
.bg-cyan-700 { background-color: $cyan-700 !important; color: white !important; }
.bg-cyan-800 { background-color: $cyan-800 !important; color: white !important; }
.bg-cyan-900 { background-color: $cyan-900 !important; color: white !important; }

.bg-gray-100 { background-color: $gray-100 !important; color: $gray-900 !important; }
.bg-gray-200 { background-color: $gray-200 !important; color: $gray-900 !important; }
.bg-gray-300 { background-color: $gray-300 !important; color: $gray-900 !important; }
.bg-gray-400 { background-color: $gray-400 !important; color: $gray-900 !important; }
.bg-gray-500 { background-color: $gray-500 !important; color: $gray-900 !important; }
.bg-gray-600 { background-color: $gray-600 !important; color: white !important; }
.bg-gray-700 { background-color: $gray-700 !important; color: white !important; }
.bg-gray-800 { background-color: $gray-800 !important; color: white !important; }
.bg-gray-900 { background-color: $gray-900 !important; color: white !important; }

.text-blue-100 { color: $blue-100 !important; }
.text-blue-200 { color: $blue-200 !important; }
.text-blue-300 { color: $blue-300 !important; }
.text-blue-400 { color: $blue-400 !important; }
.text-blue-500 { color: $blue-500 !important; }
.text-blue-600 { color: $blue-600 !important; }
.text-blue-700 { color: $blue-700 !important; }
.text-blue-800 { color: $blue-800 !important; }
.text-blue-900 { color: $blue-900 !important; }

.text-indigo-100 { color: $indigo-100 !important; }
.text-indigo-200 { color: $indigo-200 !important; }
.text-indigo-300 { color: $indigo-300 !important; }
.text-indigo-400 { color: $indigo-400 !important; }
.text-indigo-500 { color: $indigo-500 !important; }
.text-indigo-600 { color: $indigo-600 !important; }
.text-indigo-700 { color: $indigo-700 !important; }
.text-indigo-800 { color: $indigo-800 !important; }
.text-indigo-900 { color: $indigo-900 !important; }

.text-purple-100 { color: $purple-100 !important; }
.text-purple-200 { color: $purple-200 !important; }
.text-purple-300 { color: $purple-300 !important; }
.text-purple-400 { color: $purple-400 !important; }
.text-purple-500 { color: $purple-500 !important; }
.text-purple-600 { color: $purple-600 !important; }
.text-purple-700 { color: $purple-700 !important; }
.text-purple-800 { color: $purple-800 !important; }
.text-purple-900 { color: $purple-900 !important; }

.text-pink-100 { color: $pink-100 !important; }
.text-pink-200 { color: $pink-200 !important; }
.text-pink-300 { color: $pink-300 !important; }
.text-pink-400 { color: $pink-400 !important; }
.text-pink-500 { color: $pink-500 !important; }
.text-pink-600 { color: $pink-600 !important; }
.text-pink-700 { color: $pink-700 !important; }
.text-pink-800 { color: $pink-800 !important; }
.text-pink-900 { color: $pink-900 !important; }

.text-red-100 { color: $red-100 !important; }
.text-red-200 { color: $red-200 !important; }
.text-red-300 { color: $red-300 !important; }
.text-red-400 { color: $red-400 !important; }
.text-red-500 { color: $red-500 !important; }
.text-red-600 { color: $red-600 !important; }
.text-red-700 { color: $red-700 !important; }
.text-red-800 { color: $red-800 !important; }
.text-red-900 { color: $red-900 !important; }

.text-orange-100 { color: $orange-100 !important; }
.text-orange-200 { color: $orange-200 !important; }
.text-orange-300 { color: $orange-300 !important; }
.text-orange-400 { color: $orange-400 !important; }
.text-orange-500 { color: $orange-500 !important; }
.text-orange-600 { color: $orange-600 !important; }
.text-orange-700 { color: $orange-700 !important; }
.text-orange-800 { color: $orange-800 !important; }
.text-orange-900 { color: $orange-900 !important; }

.text-yellow-100 { color: $yellow-100 !important; }
.text-yellow-200 { color: $yellow-200 !important; }
.text-yellow-300 { color: $yellow-300 !important; }
.text-yellow-400 { color: $yellow-400 !important; }
.text-yellow-500 { color: $yellow-500 !important; }
.text-yellow-600 { color: $yellow-600 !important; }
.text-yellow-700 { color: $yellow-700 !important; }
.text-yellow-800 { color: $yellow-800 !important; }
.text-yellow-900 { color: $yellow-900 !important; }

.text-green-100 { color: $green-100 !important; }
.text-green-200 { color: $green-200 !important; }
.text-green-300 { color: $green-300 !important; }
.text-green-400 { color: $green-400 !important; }
.text-green-500 { color: $green-500 !important; }
.text-green-600 { color: $green-600 !important; }
.text-green-700 { color: $green-700 !important; }
.text-green-800 { color: $green-800 !important; }
.text-green-900 { color: $green-900 !important; }

.text-teal-100 { color: $teal-100 !important; }
.text-teal-200 { color: $teal-200 !important; }
.text-teal-300 { color: $teal-300 !important; }
.text-teal-400 { color: $teal-400 !important; }
.text-teal-500 { color: $teal-500 !important; }
.text-teal-600 { color: $teal-600 !important; }
.text-teal-700 { color: $teal-700 !important; }
.text-teal-800 { color: $teal-800 !important; }
.text-teal-900 { color: $teal-900 !important; }

.text-cyan-100 { color: $cyan-100 !important; }
.text-cyan-200 { color: $cyan-200 !important; }
.text-cyan-300 { color: $cyan-300 !important; }
.text-cyan-400 { color: $cyan-400 !important; }
.text-cyan-500 { color: $cyan-500 !important; }
.text-cyan-600 { color: $cyan-600 !important; }
.text-cyan-700 { color: $cyan-700 !important; }
.text-cyan-800 { color: $cyan-800 !important; }
.text-cyan-900 { color: $cyan-900 !important; }

.text-gray-100 { color: $gray-100 !important; }
.text-gray-200 { color: $gray-200 !important; }
.text-gray-300 { color: $gray-300 !important; }
.text-gray-400 { color: $gray-400 !important; }
.text-gray-500 { color: $gray-500 !important; }
.text-gray-600 { color: $gray-600 !important; }
.text-gray-700 { color: $gray-700 !important; }
.text-gray-800 { color: $gray-800 !important; }
.text-gray-900 { color: $gray-900 !important; }

.text-alpha { color: #50b64a; }
